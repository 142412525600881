<template>
  <div class="patient-info-wrapper pb20">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="scss">
</style>